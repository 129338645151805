import React, { useCallback } from 'react'
import { ChainId, Currency, Token } from '@sonefi-dex/sdk'
import styled from 'styled-components'
import {
  Button,
  Text,
  ErrorIcon,
  ArrowUpIcon,
  MetamaskIcon,
  Flex,
  Box,
  Link,
  Spinner,
  Modal,
  InjectedModalProps,
  // CheckmarkCircleIcon,
  ConfirmIcon,
} from 'packages/uikit'
import { registerToken } from 'utils/wallet'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { WrappedTokenInfo } from 'state/types'
import { RowFixed } from '../Layout/Row'
import { AutoColumn, ColumnCenter } from '../Layout/Column'
import { getBscScanLink } from '../../utils'

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 0;
`

const ConfirmedIcon = styled(ColumnCenter)`
  width: 130px;
  height: 130px;
  margin: auto;
  justify-content: center;
  border-radius: 100%;
  border: 8px solid var(--primary);
`

const StyledFlex = styled(Flex)`
  justify-content: center;
  padding: 24px 0;
`

const StyledText = styled(Text)`
  ${({ theme }) => theme.mediaQueries.xxs} {
    text-align: center;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    text-align: unset;
  }
`

const RenderText = ({ pendingText }) => {
  if (pendingText.action === 'Add') {
    return (
      <>
        <Text fontSize="16px" fontWeight="300" textAlign="center">
          <Text as="span" fontSize="16px">
            {pendingText.action}
          </Text>{' '}
          <Text as="span" fontSize="16px" bold>
            {pendingText.fromAmount} {pendingText.fromUnit}
          </Text>{' '}
          and{' '}
          <Text as="span" fontSize="16px" bold>
            {pendingText.toAmount} {pendingText.toUnit}
          </Text>{' '}
          {/* <br /> */}
          to SoneFinance
        </Text>
      </>
    )
  }

  if (pendingText.action === 'Swapping') {
    return (
      <>
        <Text fontSize="16px" fontWeight="300" textAlign="center">
          <Text as="span" fontSize="16px" bold>
            {pendingText.action}
          </Text>{' '}
          <Text as="span" fontSize="16px" bold>
            {pendingText.fromAmount} {pendingText.fromUnit}
          </Text>{' '}
          to{' '}
          <Text as="span" fontSize="16px" bold>
            {pendingText.toAmount} {pendingText.toUnit}
          </Text>{' '}
        </Text>
      </>
    )
  }

  if (pendingText.action === 'Removing') {
    return (
      <>
        <Text fontSize="16px" fontWeight="300" textAlign="center">
          <Text as="span" fontSize="16px" bold>
            {pendingText.action}
          </Text>{' '}
          <Text as="span" fontSize="16px" bold>
            {pendingText.fromAmount} {pendingText.fromUnit}
          </Text>{' '}
          and{' '}
          <Text as="span" fontSize="16px" bold>
            {pendingText.toAmount} {pendingText.toUnit}
          </Text>{' '}
          {/* <br /> */}
          from SoneFinance
        </Text>
      </>
    )
  }

  return null
}

function ConfirmationPendingContent({ pendingText }: { pendingText?: string | React.ReactNode }) {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <StyledFlex>
        <Spinner />
      </StyledFlex>
      <AutoColumn gap="12px" justify="center">
        <StyledText>{t('Waiting For Confirmation')}</StyledText>
        <AutoColumn gap="12px" justify="center">
          <RenderText pendingText={pendingText} />
        </AutoColumn>
        <Text textAlign="center" fontSize="16px">
          {t('Confirm this transaction in your wallet')}
        </Text>
      </AutoColumn>
    </Wrapper>
  )
}

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
  pendingText,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
  pendingText?: string | React.ReactNode
}) {
  const { library } = useActiveWeb3React()

  const { t } = useTranslation()

  const token: Token | undefined = wrappedCurrency(currencyToAdd, chainId)

  return (
    <Wrapper>
      <Section style={{ gap: '20px' }}>
        <ConfirmedIcon>
          <ConfirmIcon width="75px" color="primary" />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify="center">
          <StyledText fontSize="22px">{t('Success')}</StyledText>

          <RenderText pendingText={pendingText} />

          {chainId && hash && (
            <Link external small href={getBscScanLink(hash, 'transaction', chainId)}>
              <StyledText fontSize="22px" color="primary">
                {t('View on Explorer')}
              </StyledText>
            </Link>
          )}

          <Button width="100%" onClick={onDismiss}>
            {t('Close')}
          </Button>

          {currencyToAdd && library?.provider?.isMetaMask && (
            <Button
              variant="text"
              width="fit-content"
              onClick={() =>
                registerToken(
                  token.address,
                  token.symbol,
                  token.decimals,
                  token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                )
              }
            >
              <RowFixed>
                <Text fontSize="18px" color="primary">
                  {t('Add %asset% to Metamask', { asset: currencyToAdd.symbol })}
                </Text>
                {/* <MetamaskIcon ml="6px" /> */}
              </RowFixed>
            </Button>
          )}
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Box>{topContent()}</Box>
      <Box>{bottomContent()}</Box>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AutoColumn justify="center">
        <ErrorIcon color="white" width="64px" />
        <Text style={{ textAlign: 'center', width: '85%', wordBreak: 'break-word' }}>{message}</Text>
      </AutoColumn>

      <Flex justifyContent="center" pt="24px">
        <Button variant="primary" onClick={onDismiss}>
          {t('Dismiss')}
        </Button>
      </Flex>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText?: string | React.ReactNode
  currencyToAdd?: Currency | undefined
}

const TransactionConfirmationModal: React.FC<InjectedModalProps & ConfirmationModalProps> = ({
  title,
  onDismiss,
  customOnDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd,
}) => {
  const { chainId } = useActiveWeb3React()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss?.()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  return (
    <Modal
      title={
        hash ? (
          <>
            <Flex alignItems="center">
              <Flex width="25px" mr="6px">
                <img src="/images/network-logo.svg" alt="" />
              </Flex>
              <Text fontSize="18px">Soneium</Text>
            </Flex>
          </>
        ) : (
          title
        )
      }
      headerBackground="gradients.cardHeader"
      onDismiss={handleDismiss}
      bodyPadding="12px 24px 16px"
    >
      {attemptingTxn ? (
        <ConfirmationPendingContent pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={handleDismiss}
          currencyToAdd={currencyToAdd}
          pendingText={pendingText}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}

export default TransactionConfirmationModal
