import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 57">
      <path
        d="M27.794 57C25.5256 57 23.2572 56.1006 21.5716 54.5321L3.53295 37.4559C0.926933 35.0339 0.11001 31.6852 1.34833 28.5C2.71357 24.9964 6.2166 22.5484 9.86873 22.5484C12.0102 22.5484 14.0297 23.3735 15.7079 24.9332L27.295 36.0083L59.1414 2.676C60.8122 0.925449 62.8687 0 65.0854 0C68.5527 0 71.8549 2.29318 73.3002 5.70508C74.6655 8.92867 74.005 12.3988 71.5333 14.9881L33.8525 54.4256C32.2667 56.0845 30.1154 57 27.794 57Z"
        fill="#C17CCF"
      />
    </Svg>
  )
}

export default Icon
