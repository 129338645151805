import { Currency, Pair, Token } from '@sonefi-dex/sdk'
import {
  Button,
  //  ChevronDownIcon,
  Text,
  useModal,
  Flex,
  Box,
  MetamaskIcon,
  Down,
  WalletFilledIcon,
} from 'packages/uikit'
import styled from 'styled-components'
import { registerToken } from 'utils/wallet'
import { isAddress } from 'utils'
import { useTranslation } from 'contexts/Localization'
import { WrappedTokenInfo } from 'state/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { isMobile } from 'react-device-detect'
import { FlexGap } from 'components/Layout/Flex'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
// import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import { Input as NumericalInput } from './NumericalInput'
import { CopyButton } from '../CopyButton'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'primary', scale: 'sm' })<{ isPair?: boolean }>`
  height: auto;
  padding: 9px 5px;
  min-width: ${({ isPair }) => (isPair ? '175px' : '120px')};
  height: 75px;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  width: 100%;
  height: 100%;
`
const InputPanel = styled.div`
  position: relative;
  background-color: var(--white);

  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  height: 100%;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`
const Container = styled.div`
  border-radius: 16px;
  background-color: transparent;
  width: 100%;
`

const StyledBox = styled(Box)`
  background: transparent;

  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  ${({ theme }) => theme.mediaQueries.xxs} {
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    flex-direction: unset;
  }
`

const StyledButton = styled(Button)`
  font-weight: 400;
  font-size: 20px;
  color: var(--primary-dark);
  background: transparent;
  padding: 3px;

  &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    opacity: unset;
  }

  &:active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
    opacity: unset;
    transform: translateY(1px);
    box-shadow: none;
  }
`

const Position = styled(Flex)`
  padding: 1rem 0;

  ${({ theme }) => theme.mediaQueries.xxs} {
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    position: absolute;
    bottom: 80%;
    right: 0;
  }
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
}: CurrencyInputPanelProps) {
  const { account, library } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const token = pair ? pair.liquidityToken : currency instanceof Token ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )

  const balance = parseFloat(selectedCurrencyBalance?.toSignificant(6))

  const onPercent = (percent) => {
    if (percent >= 0 && percent <= 1 && balance !== undefined) {
      const percentValue = balance * percent
      onUserInput(percentValue.toString())
    }
  }

  const textSelect = 'Select'

  return (
    <StyledBox position="relative" id={id}>
      <Flex width={isMobile ? '100%' : '100%'} alignItems="start" flexDirection="column">
        <FlexGap width="100%" justifyContent="space-between" mb={label ? '10px' : '0px'}>
          <Text fontWeight="300">{label}</Text>

          {!hideBalance ? (
            <Flex alignItems="center" justifyContent="flex-end" mb={!label ? '10px' : '0px'}>
              {token && tokenAddress ? (
                <Flex style={{ gap: '5px' }} alignItems="center" mr="8px">
                  {/* <CopyButton
                  width="16px"
                  buttonColor="white"
                  text={tokenAddress}
                  tooltipMessage={t('Token address copied')}
                  tooltipTop={-20}
                  tooltipRight={40}
                  tooltipFontSize={12}
                /> */}
                  {library?.provider?.isMetaMask && (
                    <MetamaskIcon
                      style={{ cursor: 'pointer' }}
                      width="16px"
                      onClick={() =>
                        registerToken(
                          tokenAddress,
                          token.symbol,
                          token.decimals,
                          token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                        )
                      }
                    />
                  )}
                </Flex>
              ) : null}
              <WalletFilledIcon color="transparent" />
              {account && (
                <Text
                  color="var(--white)"
                  ml="10px"
                  onClick={onMax}
                  style={{ display: 'inline', cursor: 'pointer', whiteSpace: 'nowrap' }}
                >
                  {!hideBalance && !!currency
                    ? t('%balance%', { balance: selectedCurrencyBalance?.toSignificant(6) ?? t('Loading') })
                    : ' -'}
                </Text>
              )}
            </Flex>
          ) : null}
        </FlexGap>

        <Flex width={isMobile ? '100%' : '100%'} alignItems="stretch" height="75px">
          <CurrencySelectButton
            className="open-currency-select-button"
            selected={!!currency}
            onClick={() => {
              if (!disableCurrencySelect) {
                onPresentCurrencyModal()
              }
            }}
            isPair={pair}
          >
            <Flex width="100%" justifyContent="space-between" alignItems="center" padding="0 5px 0 10px">
              <Flex alignItems="center">
                {/* {pair ? (
                  <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                ) : currency ? (
                  <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '5px' }} />
                ) : null} */}

                {pair ? (
                  <Text color="var(--white)" id="pair" bold ellipsis>
                    {pair?.token0.symbol}-{pair?.token1.symbol}
                  </Text>
                ) : (
                  <Text style={{ textAlign: 'left', whiteSpace: 'nowrap' }} color="var(--white)" id="pair" bold>
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                          currency.symbol.length - 5,
                          currency.symbol.length,
                        )}`
                      : currency?.symbol) || t(`${textSelect}`)}
                  </Text>
                )}
              </Flex>

              {/* {!disableCurrencySelect && <Down color="white" />} */}
              <Down color="white" />
            </Flex>
          </CurrencySelectButton>

          <InputPanel>
            <Flex alignItems="center" padding="0 12px">
              {account && currency && (
                <>
                  <StyledButton style={{ marginRight: '5px', width: 'auto' }} onClick={() => onPercent(0.5)} scale="xs">
                    50%
                  </StyledButton>
                  <StyledButton
                    style={{ marginRight: '5px', width: 'auto' }}
                    onClick={() => onPercent(0.75)}
                    scale="xs"
                  >
                    75%
                  </StyledButton>
                  <StyledButton onClick={() => onPercent(1)} scale="xs">
                    {t('Max')}
                  </StyledButton>
                </>
              )}
            </Flex>

            <Container>
              <LabelRow>
                <NumericalInput
                  value={value}
                  onUserInput={(val) => {
                    onUserInput(val)
                  }}
                />
              </LabelRow>
            </Container>
          </InputPanel>
        </Flex>
      </Flex>
    </StyledBox>
  )
}
